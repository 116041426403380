var $ = require("jquery");
window.jQuery = $;
var slick = require("slick-carousel");
require("@fancyapps/fancybox");

function fancyboxInit() {
    $('[data-fancybox="gallery"]').fancybox({
        smallBtn : true,
        buttons : ['close'],
        toolbar: false,
        baseTpl:
        '<div class="fancybox-container" role="dialog" tabindex="-1">' +
        '<div class="fancybox-bg"></div>' +
        '<div class="fancybox-inner">' +
        '<div class="fancybox-stage"></div>' +
        '<div class="fancybox-footer"><div class="fancybox-navigation">{{arrows}}</div><div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div></div>' +
        '<div class="fancybox-caption"><div class=""fancybox-caption__body"></div></div>' +
        '</div>' +
        '</div>',
        btnTpl: {
            arrowLeft: '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
                '<div><svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.75 16.5L1.25 9L8.75 1.5" stroke="#404042" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>' +
                '</button>',
            arrowRight: '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
                '<div><svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.25 16.5L8.75 9L1.25 1.5" stroke="#404042" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>' +
                '</button>',
        },
    });
    $(window).resize(function(e){
        fancyboxInit();
    });
}

export default fancyboxInit;

// let  $sliderOnMob = $('#aSlider');
// function mobileOnlySlider() {

//     $sliderOnMob.slick({
//         autoplay: false,
//         speed: 1000,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         arrows: false,
//         dots: true,
//         centerMode: true,
//         enterPadding: '20px',
//     });
// }
// if(window.innerWidth < 768) {
//     mobileOnlySlider();
// }

// $(window).resize(function(e){
//     if(window.innerWidth < 768) {
//         if(!$sliderOnMob.hasClass('slick-initialized')){
//             mobileOnlySlider();
//         }

//     }else{
//         if($sliderOnMob.hasClass('slick-initialized')){
//             $sliderOnMob.slick('unslick');
//         }
//     }
// });

