import $ from "jquery";
import slick from 'slick-carousel';
import fancyboxInit from './components/about.js';

import ScrollMagic from 'scrollmagic';
import {TimelineMax, TweenMax, Linear, Power3, gsap} from 'gsap';

import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);


// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

var controller = new ScrollMagic.Controller({
    globalSceneOptions: {
        triggerHook: 'onEnter'
    }
});
$(".js-section").each(function () {
    new ScrollMagic.Scene({
        triggerElement: this
    })
    .setClassToggle(this, "is-active")
    .reverse(false)
    // .addIndicators()
    .addTo(controller);
});

if (typeof window.IntersectionObserver != "undefined") {
    var animateImage = function () {

        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.25
        };

        var revealCallback = function (entries, self) {

            entries.forEach(function (entry) {
                var container = entry.target;
                var img = entry.target.querySelector(".js-animate-image");
                var easeInOut = "power3.out";
                var revealAnim = gsap.timeline({
                    ease: easeInOut
                });
                // console.log(container);
                // console.log(img);
                if (entry.isIntersecting) {
                    // console.log('sdsfdf');
                    revealAnim.set(container, {
                        visibility: "visible"
                    });
                    revealAnim.fromTo(
                        container, {
                            clipPath: "polygon(0 0, 0 0, 100% 0, 100% 0%)",
                            webkitClipPath: "polygon(0 0, 0 0, 100% 0, 100% 0%)"
                        }, {
                            clipPath: "polygon(0 0, 0 100%, 100% 100%, 100% 0)",
                            webkitClipPath: "polygon(0 0, 0 100%, 100% 100%, 100% 0)",
                            duration: 1,
                            ease: easeInOut
                        }
                    );
                    revealAnim.from(img, 4, {
                        scale: 1.4,
                        ease: easeInOut,
                        delay: -1
                    });
                    self.unobserve(entry.target);
                }
            });
        };

        var revealObserver = new IntersectionObserver(revealCallback, options);

        document.querySelectorAll(".js-animate-wrapper").forEach(function (reveal) {
            revealObserver.observe(reveal);
        });
    }

    animateImage();

}



let lastScrollTop = 0;
$(window).scroll(function () {
   const st = $(this).scrollTop();
   const $header = $('.layout__header');

   if($header){
       if($(window).scrollTop() > 10){
           $('.layout__header').addClass('is-fixed');
       } else {
           $('.layout__header').removeClass('is-fixed');
       }
        if(!$(window).width() > 1024){
            if (st > lastScrollTop){
                $('.layout__header').removeClass('is-fixed');
            } else if(st <= lastScrollTop) {
                if($(window).scrollTop() > 100){
                    $('.layout__header').addClass('is-fixed');
                } else {
                    $('.layout__header').removeClass('is-fixed');
                }
            }
            lastScrollTop = st;
        }
   }

});


function smoothScroll() {
    var links = $('a[href*="#"]:not([href="#"]):not(".link-with-hash")');

    function scrollTo($target) {
        $('html, body').animate({
            scrollTop: $target.offset().top
        }, 500);
    }
    links.on('click', function(e) {
        var id = "#" + $(this).attr('href').split("#").slice(-1)[0];
        var $target = $(id);
        if ($target.length) {
            e.preventDefault();
            scrollTo($target);
        }
    });
}


let mobMenu = false;
if($(window).width() < 1200){
    mobMenu = true;
}

// menu animation
const nav = {
    elem: {
        wrapper: $('.main-menu__wrapper'),
        bg: $('.main-menu__bg'),
        close: $('.main-menu__close'),
        items: $('.main-menu__body .menu-item, .main-menu__body .btn'),
    },
    leave(){
        const tl = new TimelineMax();
        if(nav.elem.wrapper.length > 0 && mobMenu === true){
            tl
            .staggerFromTo(nav.elem.items, .4,
                {
                    opacity: 1
                },
                {
                    opacity: 0
                },
                0.1
            )

            .fromTo(nav.elem.close, .4,
                {
                    opacity: 1
                },
                {
                    opacity: 0
                },
            .4)
            .fromTo(nav.elem.bg, 0.7,
                {
                    transformOrigin: "50% 100%",
                    scaleY: 1,
                    opacity: 1
                },
                {
                    scaleY: 0,
                    opacity: 0,
                    onComplete(){
                        $('body').removeClass('menu-open');
                    }
                },
            .5)
            .fromTo(nav.elem.wrapper, .7,
                {
                    transformOrigin: "50% 100%",
                    scaleY: 1,
                    opacity: 1
                },
                {
                    scaleY: 0,
                    opacity: 0
                },
            0.7)
        } else {
            $('body').removeClass('menu-open');
        }
    },
    enter(){
        const tle = new TimelineMax();
        if(nav.elem.wrapper.length > 0 && mobMenu === true){
            tle
            .fromTo(nav.elem.wrapper, 0.4,
                {
                    transformOrigin: "50% 100%",
                    scaleY: 0,
                    opacity: 0
                },
                {
                    scaleY: 1,
                    opacity: 1
                }
            ,)
            .fromTo(nav.elem.bg, .4,
                {
                    transformOrigin: "50% 100%",
                    scaleY: 0,
                    opacity: 0
                },
                {
                    scaleY: 1,
                    opacity: 1
                },
             .2)
            .fromTo(nav.elem.close, .7,
                {
                    opacity: 0
                },
                {
                    opacity: 1
                },
             .5)
            .staggerFromTo(nav.elem.items, .7,
                {
                    y: '1.5em',
                    opacity: 0
                },
                {
                    y: 0,
                    opacity: 1
                },
                0.1, .7
            )
        }
    }
}

$(document).on('click', '.menu-trigger', function(){
    $('body').addClass('menu-open');
    nav.enter();
});
$(document).on('click', '.main-menu__wrapper .close-menu', function(){
    nav.leave();
});
$(document).on('click', '.menu-item', function(){
    nav.leave();
});

var $introSlider = $('#iSlider');
$introSlider.slick({
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: true,
    fade: true,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                appendDots: $('.intro__ban-text')
            }
        }
    ]
})
$introSlider.on('afterChange', function(event, slick, currentSlide){
    $('.slick-slide[data-slick-index="'+currentSlide+'"] .js-slider-section', $introSlider).addClass('is-active');
});


/**
 * @param wrapper jquery obj wrapper for lines;
 * @param decorPositionX jquery obj for position decor lines;
 * @param elemGap number;
 */
var decorIntroLine = function(wrapper, decorPositionX, elemGap) {
    var self = this;
    this.elems = {
        wrapper: wrapper,
        elemGap: elemGap || 30,
        $decorLine1: $('.js-decor-1'),
        $decorLine2: $('.js-decor-2'),
        // $interface: $('#introInterface'),
        // $interfaceNum: $('#introInterface .intro-slider__ii'),
        // $interfaceActiveNum: $('#introInterface .intro-slider__ii.is-active'),
    };
    this.inResize = function(){
        $(window).on('resize', function(){
            self.updatePositionX();
            // introTextBlockPosition();
            /// ets function;
        })
    }
    // this.inScroll = function(){
    //     $( window ).scroll(function() {

    //         // self.updatePositionY();
    //         /// ets function;
    //     })
    // }
    this.introInterfaceButton = function(){
        let wrapperPosition = decorPositionX[0].getBoundingClientRect();
        let onMob = ($(window).width() > 1199) ? false : true;

        // function animationChangeNum(toIndex){

        //     let currentActive = $('#introInterface .intro-slider__ii.is-active');
        //     let elemNotActiveWidth = (self.elems.$interface.width() - (wrapperPosition['width'] + 62)) /  (self.elems.$interfaceNum.length - 1);
        //     let targetElem = $('#introInterface .intro-slider__ii').eq(toIndex);
        //     let elemNotActive = $('#introInterface .intro-slider__ii').not(targetElem);

        //     let _upTl = new TimelineMax();

        //     console.log(onMob);
        //     if(!onMob){
        //         _upTl

        //         .to(elemNotActive, 0,
        //             {
        //                 width : elemNotActiveWidth,
        //             }
        //         )
        //         .to(targetElem, 0,
        //             {
        //                 width : wrapperPosition['width'] + 62,
        //             }, 0
        //         )
        //         .to(currentActive.find('.num'), 0.3,
        //             {
        //                 top: 0,
        //             }, 0
        //         )
        //         .to(currentActive.find('.text'), 0.3,
        //             {
        //                 opacity: 0
        //             }, 0
        //         )
        //         .to(targetElem.find('.num'), 0.3,
        //             {
        //                 top: '-10%',
        //             }, 0
        //         )
        //         .to(targetElem.find('.text'), 0.3,
        //             {
        //                 opacity: 1
        //             }
        //         )

        //         // .to(targetElem.find('.text'), 0,
        //         //     {
        //         //         top: '-18px'
        //         //     }, 0
        //         // )

        //     }

        //     currentActive.removeClass('is-active');
        //     targetElem.addClass('is-active');
        // }


        // $(function(){
        //     //on load
        //     if(!onMob){
        //         $('#introInterface .intro-slider__ii:first-child .text').css({
        //             opacity: 1,

        //         });
        //         $('#introInterface .intro-slider__ii:first-child .num').css({
        //             top: '-10%'
        //         });
        //     }

        //     // afterchange slider
        //     $introSlider.on('afterChange', function(event, slick, currentSlide){
        //         animationChangeNum(currentSlide);
        //     })

        //     //on click
        //     $(self.elems.$interfaceNum).on('click', function(){
        //         if($(this).hasClass('is-active')){
        //             return;
        //         }
        //         // animationChangeNum($(this).index());
        //         $introSlider.slick('slickGoTo', $(this).index());

        //     })
        // })
    }
    this.init  = function(){
        // self.introInterfaceButton();
    }


    this.updatePositionX = function(){
        let wrapperPosition = decorPositionX[0].getBoundingClientRect();

        // let currentActive = $('#introInterface .intro-slider__ii.is-active');
        // self.elems.$decorLine1.animate({
        //     'left': wrapperPosition['x'] - 30
        // },500, function(){
        //     console.log('complete');
        // });
        // console.log(self.elems.$interfaceActiveNum);
        if($(window).width() > 1199){
            const _upTl = new TimelineMax();
            _upTl
                // .set(currentActive,
                //     {
                //         width : wrapperPosition['width'] + 62,
                //     }
                // )
                .to(self.elems.$decorLine1, 1,

                    {
                        opacity: 1,
                        left: wrapperPosition['x'] - 30
                    }
                )
                .to(self.elems.$decorLine2, 1,

                    {
                        opacity: 1,
                        left: (wrapperPosition['x'] + wrapperPosition['width']) + 30
                    }, .1
                )

                // .to(self.elems.$interface, 1,

                //     {
                //         opacity: 1,
                //         left: wrapperPosition['x'] - 30
                //     }, .3
                // )
        }

    }


    this.updatePositionY = function(elem){
        let elemPosition = elem[0];
        let elemH = elemPosition.getBoundingClientRect();
        let elemOffset = $(elemPosition).offset();
        let intro = $('.intro').offset();
        // let percentageTop = ((elemPosition['y'] - self.elems.elemGap) / $(window).height()) * 100 + '%';
        // let percentageBottom = ((elemPosition['y'] + elemPosition['height'] + self.elems.elemGap) / $(window).height()) * 100 + '%';

        let percentageTop = ((elemOffset['top'] - intro['top'] - self.elems.elemGap) / $(window).height()) * 100 + '%';
        let percentageBottom = (((elemOffset['top'] - intro['top']) + elemH['height'] + self.elems.elemGap) / $(window).height()) * 100 + '%';
        // console.log(percentageTop);
        // console.log(elemOffset['top']);
        // $("body").get(0).style.setProperty('--js-header-decor-1', percentageTop);
        // $("body").get(0).style.setProperty('--js-header-decor-2', percentageBottom);

        const _upTl2 = new TimelineMax();
        _upTl2
            .to(self.elems.$decorLine1, 1,

                {
                    backgroundImage: `linear-gradient(to bottom, rgba(255,255,255, .2) 0%, rgba(255,255,255, .2) ${percentageTop}, rgba(255,255,255, 0.0) ${percentageTop}, rgba(255,255,255, 0.0) ${percentageBottom}, rgba(255,255,255, 0.2) ${percentageBottom}, rgba(255,255,255, 0.2) 100%)`
                }
            );

    }
    this.init();
    return this;
}

// function introTextBlockPosition(){
//     const mediaQuery = window.matchMedia('(max-width: 1023px)');
//     setTimeout(function(){
//         let init = false;
//         if(mediaQuery.matches){
//             if(init === false){
//                 let $wrapper = $('.intro');
//                 let $target = $('.intro__ban-text');
//                 let bottomElems = $('.intro__goto, .intro__down-btn, .intro-slider .slick-dots');
//                 if($target.length > 0 && bottomElems.length > 0 && $wrapper.length > 0){
//                     let bottomOffset = $target[0].getBoundingClientRect();
//                     let introHeight = $wrapper[0].getBoundingClientRect();
//                     // console.log(introHeight);
//                     console.log(bottomOffset);
//                     console.log(introHeight);
//                     console.log(((introHeight['top'] - bottomOffset['top']) * -1) - 20);
//                     $.each(bottomElems, function(i,e){
//                         let _that = e;

//                         $(e).animate({
//                             top: ((introHeight['top'] - bottomOffset['top']) * -1) - 20,
//                         }, 300)
//                         setTimeout(() => {
//                             $(e).css({
//                                 bottom: 'auto',
//                                 transform: 'translateY(-100%)'

//                             })
//                         }, 300);
//                         console.log('sdfsdf');
//                     });
//                     init = true;
//                     // console.log(init) ;

//                 }
//             }

//         } else {;
//             if(init === true) {
//                 let gotoText = $('.intro__goto');
//                 gotoText.css({bottom: '16vh'})
//             }
//         }
//     }, 100);

// }


let  $sliderOnMob = $('.js-slider-onMob');
function mobileOnlySlider() {

    $sliderOnMob.slick({
        autoplay: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        adaptiveHeight: true,
        centerMode: true,
        centerPadding: '20px',
    });
}
if(window.innerWidth < 768) {
    mobileOnlySlider();
}

$(window).resize(function(e){
    if(window.innerWidth < 768) {
        if(!$sliderOnMob.hasClass('slick-initialized')){
            mobileOnlySlider();
        }

    }else{
        if($sliderOnMob.hasClass('slick-initialized')){
            $sliderOnMob.slick('unslick');
        }
    }
});




$(function($){
    nav.leave();
    smoothScroll();

    //decor line
    if($('body').hasClass('main-page')){
        var decorLine = new decorIntroLine($('.js-decor-wrapper'), $('.js-decor-position'));

    }

    $introSlider.on('afterChange', function(){
        decorLine.updatePositionY($('.slick-current .js-decor-height'));
    })
    $(".preloader").delay(2000).fadeOut("slow");
    setTimeout(() => {
        if($('body').hasClass('main-page')){
            $('.slick-slide[data-slick-index="0"] .js-slider-section').addClass('is-active');
            decorLine.updatePositionX();
            decorLine.updatePositionY($('.slick-current .js-decor-height'));
            decorLine.inResize();
            // decorLine.inScroll();
        }

    }, 2000);

})



//slider height
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


//test
// window.App = function(options){
//     var self = this;
//     this._options;

//     function _init(){
//         self.block1 = $('.selector');
//         self._options = options || {};
//         return self;
//     }

//     this.testFunction = function(){
//         console.log(self._options.cls);
//     }

//     // code


//     return _init();
// }

// window.app = new App({cls: 'one'});
// window.app1 = new App({cls: 'two'});

// app.testFunction();
// app1.testFunction();



//shodibek
function popUpTrigger() {
    $(document).on('click', '#cPop-trigger', function(e){
        e.preventDefault();
        $('.modal').fadeIn();
        $('body').addClass('modal-open');
    });
    $(document).on('click', '.modal .close', function(e){
        e.preventDefault();
        $('.modal').fadeOut();
        $('body').removeClass('modal-open');
    });
}


function onlyNumberInput() {
    let telInput = document.querySelectorAll('input[type="tel"]');

	function dpcm(input) {
		let value = input.value;
		let re = /[^0-9\-\(\)\+\' ']/gi;
		if (re.test(value)) {
			value = value.replace(re, '');
			input.value = value;
		}
	}

	for (let i = 0; i < telInput.length; i++) {
		telInput[i].oninput = function () {
			dpcm(this);
		};
	}
}

fancyboxInit();
$(function() {
    // nav.leave();
    // smoothScroll();
    popUpTrigger();
    onlyNumberInput();

});

